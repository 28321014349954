/* App.css */
*{
  margin: 0;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .part {
    display: flex;
    flex-direction: column; /* Stack images vertically */
    align-items: center; /* Center images horizontally */
  }
  
  .part img {
    width: 100%; /* Set the width to 90% of the container */
    height: auto; /* Maintain the aspect ratio */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
  }
  
  @media (min-width: 768px) {
    .part {
      flex-direction: row; /* Align images in rows */
      flex-wrap: wrap; /* Wrap images to the next row */
      justify-content: space-between;
    }
  
    .part img {
      width: 48%; /* Two images per row on larger screens */
    }
  }
  
  @media (min-width: 1024px) {
    .part img {
      width: 23%; /* Four images per row on larger screens */
    }
  }
  